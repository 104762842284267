<template>
    
    <div>
        <b-row>
            <b-col
                sm="12"
            >
                <statistic-card-horizontal
                    icon="UserIcon"
                    color="info"
                    :statistic="providerH"
                    statistic-title="Proveedor"
                />
            </b-col>
            <b-col
                md="6"
                sm="12"
            >
                <statistic-card-horizontal
                    icon="TargetIcon"
                    color="info"
                    :statistic="scoreH + ' %'"
                    statistic-title="Porcentaje obtenido"
                />
            </b-col>
            <b-col
                md="6"
                sm="12"
            >
                <statistic-card-horizontal
                    icon="CalendarIcon"
                    color="info"
                    :statistic="dateH"
                    statistic-title="Fecha de Evaluación"
                />
            </b-col>
        </b-row>
        
        <workers-import-file
            :form="form_id"
            :tariff_id="tariff_id"
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            @refetch-data="refetchData"
        />
        
        <b-row>
             <b-col :cols="tariff_id ? '9': '12'">
                <b-button       
                    @click="viewWorkers()"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"	
                    variant="primary"	
                    size="lg"			
                    block
                >
                    <feather-icon size="1x" icon="FileTextIcon" /> Planilla         
                </b-button> 
            </b-col>
            <b-col cols="3" v-if="tariff_id">                
                <ButtonUpload 
                    :code="codeArr[14]" 
                    :isFromPlanilla="true" 
                /> 
            </b-col>
        </b-row>
        
        <b-modal
            ref="refPlantillaModal"
            size="xl"
            centered
            title-tag="div"
            header-bg-variant="primary"
            header-text-variant="white"
            title="PLANILLA DE EMPLEADOS CARGADOS"
            no-close-on-backdrop
            ok-only
            ok-title="ACEPTAR"
        >
            <b-alert
                variant="info"
                show
            >
                <div class="alert-body">
                    <ul class="mb-0">
                       <!--  <li>
                            Los datos han sido cargados con respecto al mes anterior. Actualice la lista si lo requiere.                                
                        </li> -->
                        <li>
                            Cargar el 100% de sus colaboradores que se encuentren en su planilla.
                        </li>
                        <li>
                            En caso se produzca algún error en la carga masiva. Se enviará un correo con los detalles, por favor revisar bandeja de entrada o de spam.
                        </li>
                    </ul>
                </div>
            </b-alert>
            
            <b-card-text class="my-2">
                
                <!-- sorting  -->
                <b-form-group
                    label="Ordenar"
                    label-size="sm"
                    label-align-sm="left"
                    label-cols-sm="2"
                    label-for="sortBySelect"
                    class="mr-1 mb-md-0"
                >
                    <b-input-group
                        size="sm"
                    >
                        <b-form-select
                            id="sortBySelect"
                            v-model="sortBy"
                            :options="sortOptions"
                        >
                            <template #first>
                                <option value="">
                                    Ninguno
                                </option>
                            </template>
                        </b-form-select>
                        <b-form-select
                            v-model="sortDesc"
                            size="sm"
                            :disabled="!sortBy"
                        >
                            <option :value="false">
                                Asc
                            </option>
                            <option :value="true">
                                Desc
                            </option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>

                <!-- filter -->
                <b-form-group
                    label="Fitrar"
                    label-cols-sm="2"
                    label-align-sm="left"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0 mt-1"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Buscar ..."
                        />
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter"
                                @click="filter = ''"
                            >
                                Limpiar
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <b-row align-v="center" align-h="between">

                    <b-col
                        cols="3"
                        class="my-1"
                    >
                        <b-form-group
                            class="mb-0"
                        >
                            <label class="d-inline-block text-sm-left mr-50">Por página</label>
                            <b-form-select
                                id="perPageSelect"
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                                class="w-50"
                            />
                        </b-form-group>
                    </b-col>
                    
                    <b-col
                        cols="9"
                        class="my-1 text-right"
                    >
                        <div class="mr-2" style="display: inline-block; width: 20%;">
                            <json-excel :data="downloadWorkers()" name="planilla.xls"></json-excel>
                        </div>
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="success"
                            class="mr-2"
                            size="sm"
                            @click="openSidebar()"
                        >
                            CARGA MASIVA
                        </b-button>
                        <b-button
                            id="toggle-btn"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            size="sm"
                            @click="addWorker()"
                        >
                            AGREGAR
                        </b-button>
                    </b-col>

                </b-row>

                <b-table
                    ref="workersTable"
                    :sticky-header="stickyHeader"
                    striped
                    hover
                    responsive
                    class="position-relative"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="workers"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    empty-text="No se encontraron registros coincidentes"
                >
                    <template #cell(id)="data">
                        <span class="text-nowrap">
                            {{ data.index+1 }}
                        </span>
                    </template>

                    <template #cell(worker_state)="data">
                        <span class="text-nowrap" v-if="data.item.worker_state">
                            <b-badge
                                :id="`state-row-${data.item.id}`" 
                                :variant="`light-${ data.item.worker_state.color}`"
                            >
                                {{ data.item.worker_state.name }}
                            </b-badge>
                            <b-tooltip
                                v-if="data.item.worker_state.name === 'CESADO'"
                                :id="`tooltip-row-${data.item.id}`"
                                :target="`state-row-${data.item.id}`"
                                placement="left"
                            >
                                <p class="mb-0">
                                    Motivo
                                </p>
                                <p class="mb-0">
                                    {{ data.item.termination_reason.name }}
                                </p>
                            </b-tooltip>
                        </span>
                    </template>
                    
                    <template #cell(tariff_compliance)="data">
                        <span class="text-nowrap">
                            <b-button
                                :id="`tariff-row-${data.item.id}`"
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                :variant="`flat-${data.item.tariff_compliance == 1 ? 'success' : 'warning'}`"
                                class="btn-icon rounded-circle"
                            >
                                <feather-icon 
                                    size="1.5x"
                                    :icon="data.item.tariff_compliance == 1 ? 'CheckIcon': 'InfoIcon'"                                
                                /> 
                            </b-button>
                            <b-tooltip
                                :id="`tooltip-tariff-row-${data.item.id}`"
                                :target="`tariff-row-${data.item.id}`"
                                placement="left"
                            >
                                <p class="mb-0">
                                    {{ data.item.tariff_compliance == 1 ? 'CUMPLE': 'ADJUNTAR BOLETA' }}
                                </p>
                            </b-tooltip>
                        </span>
                    </template>

                    <template #cell(actions)="data">
                        <b-dropdown
                            variant="link"
                            no-caret
                            :right="$store.state.appConfig.isRTL">

                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </template>

                            <b-dropdown-item @click="editWorker(data.item, data.index)">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">Editar</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="deleteWorker(data.item.id)">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">Eliminar</span>
                            </b-dropdown-item>
                        </b-dropdown>                
                    </template> 
                </b-table>

                <!-- pagination -->
                <div>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </div>

            </b-card-text>
        </b-modal>
        
        <b-modal
            id="idWorkerModal"
            ref="refWorkerModal"
            title-tag="div"
            header-bg-variant="primary"
            header-text-variant="white"            
            :title="titleModal"
            @show="resetModal"
            @hidden="resetModal"            
            no-close-on-backdrop
            centered
            hide-footer
            size="lg"
        >
             <template #default="{ hide }">
                <validation-observer
                    #default="{ handleSubmit }"
                    ref="workerForm"
                >            
                    <b-form
                        @submit.prevent="handleSubmit(onSubmit)"
                        @reset.prevent="resetModal"                    
                    >
                    <b-row>
                            <b-col cols='12' md="8">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Nombre Completo"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Nombre completo"
                                        label-for="fullName"
                                    >
                                        <b-form-input
                                            id="fullName"
                                            v-model="dataWorker.fullName"
                                            type="text"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="4">
                                <validation-provider
                                    #default="{ errors }"
                                    name="DNI"
                                    rules="required|digits:8"
                                >
                                    <b-form-group
                                        label="DNI"
                                        label-for="dni"
                                    >
                                        <b-form-input
                                            id="dni"
                                            v-model="dataWorker.dni"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="8">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Puesto/Cargo"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Puesto/Cargo"
                                        label-for="roleInput"
                                    >               
                                        <v-select
                                            v-if="tariff_id"
                                            id="roleSelect"
                                            placeholder="Seleccione"
                                            v-model="dataWorker.role"
                                            :options="optionsPositons"
                                            @input="changePosition($event); validateRequiredFields();"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <b-form-input
                                            v-else
                                            id="roleInput"
                                            v-model="dataWorker.role"
                                            @input="validateRequiredFields"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            
                            <b-col cols='12' md="4">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Categoría"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Categoría"
                                        label-for="categorySelect"
                                    >                    
                                        <v-select
                                            id="categorySelect"
                                            placeholder="Seleccione"
                                            v-model="dataWorker.category"                        
                                            :options="optionsCategory"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col v-if="tariff_id" cols='12' md="4">
                                <b-form-group
                                    label="Sueldo Tarifario"
                                    label-for="salaryTariffInput"
                                >
                                    <b-form-input
                                        id="salaryTariffInput"
                                        v-model="salaryTariff"
                                        disabled
                                        readonly
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col cols='12' :md="tariff_id ? '4' : '6'">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Sueldo"
                                    rules="required|positive"
                                >
                                    <b-form-group
                                        label="Sueldo"
                                        label-for="salaryInput"
                                    >
                                        <b-form-input
                                            id="salaryInput"
                                            type="number"
                                            step=".01"
                                            v-model="dataWorker.salary"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' :md="tariff_id ? '4' : '6'">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Jornal Diario"
                                    rules="required|positive"
                                >
                                    <b-form-group
                                        label="Jornal Diario"
                                        label-for="dailyWageInput"
                                    >
                                        <b-form-input
                                            id="dailyWageInput"
                                            type="number"
                                            step=".01"
                                            v-model="dataWorker.daily_wage"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="8">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Correo"
                                    rules="email"
                                >
                                    <b-form-group
                                        label="Correo electrónico"
                                        label-for="emailInput"
                                    >
                                        <b-form-input
                                            id="emailInput"
                                            v-model="dataWorker.email"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="4">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Celular"
                                    rules="alpha-num"
                                >
                                    <b-form-group
                                        label="Celular"
                                        label-for="phoneInput"
                                    >
                                        <b-form-input
                                            id="phoneInput"
                                            v-model="dataWorker.phone"
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="4">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Estado"
                                    rules="required"
                                >
                                    <b-form-group
                                        label="Estado"
                                        label-for="workerStateSelect"
                                    >                    
                                        <v-select
                                            id="workerStateSelect"
                                            placeholder="Seleccione"
                                            v-model="dataWorker.worker_state"                        
                                            :options="optionsWorkerStates"
                                            label="name"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols='12' md="8">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Motivo de Cese"
                                >
                                    <b-form-group
                                        v-if="dataWorker.worker_state && dataWorker.worker_state.name === 'CESADO'"
                                        label="Motivo de Cese"
                                        label-for="terminationReasonSelect"
                                    >                    
                                        <v-select
                                            id="terminationReasonSelect"
                                            placeholder="Seleccione"
                                            v-model="dataWorker.termination_reason"
                                            :options="optionsTerminationReasons"
                                            label="name"
                                            :state="errors.length > 0 ? false:null"
                                            @input="validateRequiredFields"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                        </b-row>
                        
                        <hr>
                        <div class="d-flex justify-content-end">                        
                            <b-button
                                v-if="!isLoading"
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                class="mr-2"
                                type="button"
                                @click="hide"
                            >
                                CANCELAR
                            </b-button>
                            
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                type="submit"
                                :disabled="isLoading || isSubmitDisabled"
                            >
                                <div v-if="isLoading">
                                    <b-spinner small/>
                                    Cargando..
                                </div>
                                <span v-else>{{ okTitle }}</span>
                            </b-button>
                        </div>
                        
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>

        <b-row>
            <b-col xs="12">
                <b-card class="card-penalty-amounts mt-2 mb-0" header-tag="header" border-variant="primary" header-bg-variant="primary">
                    <template #header>
                        <h5 class="mb-0 text-white">MONTO DE LA PENALIDAD</h5>
                    </template>
                    <b-card-text class="pt-2">
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="infringement"><strong>INFRACCIÓN:</strong></label>
                                <v-select 
                                    id="infringement"
                                    v-model="infringement"
					                :options="infringementOptions" />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="recidivism"><strong>REINCIDENCIA:</strong></label>
                                <v-select 
                                    id="recidivism"
                                    v-model="recidivism"
					                :options="recidivismOptions" />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="impact"><strong>IMPACTO:</strong></label>
                                <v-select 
                                    id="impact"
                                    v-model="impact"
					                :options="impactOptions" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="company_size"><strong>TAMAÑO EMPRESA, SEGÚN N° DE TRABAJADORES:</strong></label>
                                <v-select 
                                    id="company_size"
                                    v-model="company_size"
					                :options="companySizeOptions" />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="monthly_valuation"><strong>VALORIZACIÓN MENSUAL:</strong></label>
                                <b-form-input id="monthly_valuation" v-model="monthly_valuation"></b-form-input>
                            </b-col>
                            <b-col xs="12" md="4" v-if="amountPenalty">
                                <label for="amount_penalty"><strong>MONTO DE LA PENALIDAD (EN BASE A LA FÓRMULA):</strong></label>
                                <b-form-input id="amount_penalty" v-model="amountPenalty" disabled readonly></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12" md="6">
                                <label for="doc_ref"><strong>DOC. REF.:</strong></label>
                                <b-form-input id="doc_ref" v-model="doc_ref"></b-form-input>
                            </b-col>
                            <b-col xs="12" md="6">
                                <label for="clause"><strong>CLAUSULA:</strong></label>
                                <b-form-input id="clause" v-model="clause"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12" md="12">
                                <hr>
                                <b-form-checkbox
                                    id="checkbox-1"
                                    v-model="penalty_fixed_amount_status"
                                    name="checkbox-1"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    ¿DEFINIR MONTO FIJO DE LA PENALIDAD?
                                </b-form-checkbox>
                            </b-col>
                            <b-col xs="12" md="6" class="mt-2" v-if="penalty_fixed_amount_status == '1'">
                                <label for="penalty_fixed_amount"><strong>MONTO FIJO DE LA PENALIDAD (EJEMPLO: 150000.25):</strong></label>
                                <b-form-input id="penalty_fixed_amount" v-model="penalty_fixed_amount"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12" md="12">
                                <hr>
                                <label for="penalty_amount_comment"><strong>COMENTARIO INTERNO DEL REVISOR:</strong></label>
                                <b-form-textarea
                                    id="penalty_amount_comment"
                                    v-model="penalty_amount_comment"
                                    placeholder="Escriba un comentario ..."
                                    rows="4"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12" md="12">
                                <hr>
                                <b-form-checkbox
                                    id="checkbox-2"
                                    v-model="eliminate_ticket_loading_validation"
                                    name="checkbox-2"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    ¿ELIMINAR VALIDACIÓN DE CARGA DE BOLETAS?
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12">
                <b-card class="card-job-criticality mt-2 mb-0" header-tag="header" border-variant="primary" header-bg-variant="primary">
                    <template #header>
                        <h5 class="mb-0 text-white">CRITICIDAD LABORAL</h5>
                    </template>
                    <b-card-text class="pt-2">
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="claims"><strong>RECLAMOS:</strong></label>
                                <v-select 
                                    id="claims"
                                    v-model="claims"
					                :options="claimOptions" />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="paralyzes"><strong>PARALIZACIÓN CORE BUSS.:</strong></label>
                                <v-select 
                                    id="paralyzes"
                                    v-model="paralyzes"
					                :options="paralyzeOptions" />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="penalties"><strong>PENALIDADES:</strong></label>
                                <v-select 
                                    id="penalties"
                                    v-model="penalties"
					                :options="penaltyOptions" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="laborDebt"><strong>DEUDA LABORAL:</strong></label>
                                <v-select 
                                    id="laborDebt"
                                    v-model="laborDebt"
					                :options="laborDebtOptions" />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="labor"><strong>LABORAL:</strong></label>
                                <v-select 
                                    id="labor"
                                    v-model="labor"
					                :options="laborOptions" />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="pensionDebt"><strong>DEUDA PROV.:</strong></label>
                                <v-select 
                                    id="pensionDebt"
                                    v-model="pensionDebt"
					                :options="pensionDebtOptions" />
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="procLab"><strong>PROC. LAB.:</strong></label>
                                <v-select 
                                    id="procLab"
                                    v-model="procLab"
					                :options="procLabOptions" />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="months"><strong>MESES:</strong></label>
                                <v-select 
                                    id="months"
                                    v-model="months"
					                :options="monthOptions" />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label><strong>SUSTENTO:</strong></label>
                                <ButtonUpload :code="codeArr[15]" :acceptedAllFiles='true' /> 
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_claims"><strong>COMENTARIO RECLAMOS:</strong></label>
                                <b-form-textarea
                                    id="comment_claims"
                                    v-model="comment_claims"
                                    placeholder="Escriba un comentario ..."
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_paralyzes"><strong>COMENTARIO PARALIZACIÓN CORE BUSS.:</strong></label>
                                <b-form-textarea
                                    id="comment_paralyzes"
                                    v-model="comment_paralyzes"
                                    placeholder="Escriba un comentario ..."
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_penalties"><strong>COMENTARIO PENALIDADES:</strong></label>
                                <b-form-textarea
                                    id="comment_penalties"
                                    v-model="comment_penalties"
                                    placeholder="Escriba un comentario ..."
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_laborDebt"><strong>COMENTARIO DEUDA LABORAL:</strong></label>
                                <b-form-textarea
                                    id="comment_laborDebt"
                                    v-model="comment_laborDebt"
                                    placeholder="Escriba un comentario ..."
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_labor"><strong>COMENTARIO LABORAL:</strong></label>
                                <b-form-textarea
                                    id="comment_labor"
                                    v-model="comment_labor"
                                    placeholder="Escriba un comentario ..."
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_pensionDebt"><strong>COMENTARIO DEUDA PROV.:</strong></label>
                                <b-form-textarea
                                    id="comment_pensionDebt"
                                    v-model="comment_pensionDebt"
                                    placeholder="Escriba un comentario ..."
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_procLab"><strong>COMENTARIO PROC. LAB.:</strong></label>
                                <b-form-textarea
                                    id="comment_procLab"
                                    v-model="comment_procLab"
                                    placeholder="Escriba un comentario ..."
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_months"><strong>COMENTARIO MESES:</strong></label>
                                <b-form-textarea
                                    id="comment_months"
                                    v-model="comment_months"
                                    placeholder="Escriba un comentario ..."
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12" md="12">
                                <label for="recommendations"><strong>RECOMENDACIONES:</strong></label>
                                <b-form-textarea
                                    id="recommendations"
                                    v-model="recommendations"
                                    placeholder="Escriba una recomendación ..."
                                    rows="2"
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12">
                <b-card class="card-extra-fields mt-2 mb-0" header-tag="header" border-variant="primary" header-bg-variant="primary">
                    <template #header>
                        <h5 class="mb-0 text-white">VALORES ADICIONALES</h5>
                    </template>
                    <b-card-text class="pt-2">
                        <b-row>
                            <b-col xs="12" md="6">
                                <label for="contract_object"><strong>OBJETO DE CONTRATO:</strong></label>
                                <b-form-input id="contract_object" v-model="contract_object"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row v-if="earrings.length">
            <b-col cols="12">
                <b-alert show variant="primary" class="mb-0 mt-2">
                    <h4 class="alert-heading">
                        <b-row align-v="center" align-h="between">
                            <b-col>LISTADO DE PENDIENTES DEL FORMULARIO ({{ previousFormDate }})</b-col>
                            <b-col cols="auto">
                                <ButtonUpload
                                    :code="codeArr[16]"
                                    :getQuestionFiles="getQuestionFiles"
                                    extra-class="d-inline-block"
                                />
                                <b-button
                                    @click="viewObservations"
                                    variant="outline-info"
                                    class="ml-1"
                                >
                                    <feather-icon size="1x" icon="EyeIcon" />  
                                    Ver Observaciones
                                </b-button>
                            </b-col>
                        </b-row>
                    </h4>
                    <div class="alert-body">

                        <div v-for="(item, index) in earrings" :key="index">
                            <span><strong>Pregunta ({{ item.number }})</strong>: {{ item.question }}</span> <br>
                            <span><strong>Observaciones</strong>:</span>
                            <div style="white-space: pre-line;" v-html="item.comments"></div>
                            
                            <hr v-if="(index + 1) !== earrings.length">
                        </div>

                    </div>
                </b-alert>
            </b-col>

            <b-modal
                ref="refObservationsModal"
                size="lg"
                ok-title="ACTUALIZAR"
                cancel-title="CANCELAR"
                cancel-variant="outline-secondary"
                title-tag="div"
                header-bg-variant="primary"
                header-text-variant="white"
                title="OBSERVACIONES"
                centered
                no-close-on-backdrop
                @ok="onSubmit2"
            >            
                <b-form-group class="mt-1">
                    <b-form-checkbox
                        v-for="(observation, index) in observations"
                        :key="observation.id"
                        :value="observation.id"
                        v-model="selectedObservations"
                        :class="{ 'mb-1': (index + 1  !== observations.length) }"
                        name="flavour-3a"
                    >
                        {{ observation.text }}  
                    </b-form-checkbox>
                </b-form-group>
            </b-modal>  
        </b-row>
                
        <b-row>
            <b-col>
                <b-form>         
                <table class="content-table table table-bordered text-center w-100 ">
                    <thead>
                        <tr>
                            <th colspan="12">CUMPLIMIENTO MENSUAL DE LAS OBLIGACIONES LABORALES</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><label>1</label></td>
                            <td colspan="3">
                                <label class="form_question">Pago Oportuno de las Remuneraciones</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__1__yes" v-model="questionsArr[0]" class="custom-control-input" v-bind:value="1" >
                                    <label class="custom-control-label" for="question__1__yes">SÍ</label> 
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__1__parcial" v-model="questionsArr[0]" class="custom-control-input" v-bind:value="2" >
                                    <label class="custom-control-label" for="question__1__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__1__not" v-model="questionsArr[0]" class="custom-control-input" v-bind:value="3"  >
                                    <label class="custom-control-label" for="question__1__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__1__na" v-model="questionsArr[0]" class="custom-control-input" v-bind:value="4" >
                                    <label class="custom-control-label" for="question__1__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[0]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[0]" @update-status="updateReview($event)" />
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[0]"  :question="codeArr[0]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>2</label></td>
                            <td colspan="3">
                                <label class="form_question">Descanso Anual Remunerado</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__2__yes" v-model="questionsArr[1]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__2__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__2__parcial" v-model="questionsArr[1]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__2__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__2__not" v-model="questionsArr[1]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__2__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__2__na" v-model="questionsArr[1]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__2__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[1]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[1]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[1]" :question="codeArr[1]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>3</label></td>
                            <td colspan="3">
                                <label class="form_question">Sistema Nacional Pensiones (SNP)</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__3__yes" v-model="questionsArr[2]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__3__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__3__parcial" v-model="questionsArr[2]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__3__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__3__not" v-model="questionsArr[2]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__3__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__3__na" v-model="questionsArr[2]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__3__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[2]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[2]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[2]" :question="codeArr[2]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>4</label></td>
                            <td colspan="3">
                                <label class="form_question">EsSalud</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__4__yes" v-model="questionsArr[3]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__4__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__4__parcial" v-model="questionsArr[3]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__4__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__4__not" v-model="questionsArr[3]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__4__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__4__na" v-model="questionsArr[3]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__4__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[3]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[3]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[3]" :question="codeArr[3]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>5</label></td>
                            <td colspan="3">
                                <label class="form_question">Fcjmms</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__5__yes" v-model="questionsArr[4]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__5__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__5__parcial" v-model="questionsArr[4]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__5__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__5__not" v-model="questionsArr[4]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__5__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__5__na" v-model="questionsArr[4]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__5__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[4]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[4]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[4]" :question="codeArr[4]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>6</label></td>
                            <td colspan="3">
                                <label class="form_question">5ta Categoría</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__6__yes" v-model="questionsArr[5]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__6__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__6__parcial" v-model="questionsArr[5]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__6__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__6__not" v-model="questionsArr[5]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__6__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__6__na" v-model="questionsArr[5]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__6__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[5]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[5]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[5]" :question="codeArr[5]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>7</label></td>
                            <td colspan="3">
                                <label class="form_question">Sistema Privado de Pensiones (AFP)</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__7__yes" v-model="questionsArr[6]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__7__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__7__parcial" v-model="questionsArr[6]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__7__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__7__not" v-model="questionsArr[6]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__7__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__7__na" v-model="questionsArr[6]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__7__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[6]"/>   
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[6]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[6]" :question="codeArr[6]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>8</label></td>
                            <td colspan="3">
                                <label class="form_question">Seguro SCTR (Salud y Pensión)</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__8__yes" v-model="questionsArr[7]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__8__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__8__parcial" v-model="questionsArr[7]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__8__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__8__not" v-model="questionsArr[7]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__8__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__8__na" v-model="questionsArr[7]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__8__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[7]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[7]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[7]" :question="codeArr[7]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>9</label></td>
                            <td colspan="3">
                                <label class="form_question">Seguro Vida Ley (Empleados y Obreros)</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__9__yes" v-model="questionsArr[8]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__9__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__9__parcial" v-model="questionsArr[8]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__9__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__9__not" v-model="questionsArr[8]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__9__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__9__na" v-model="questionsArr[8]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__9__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[8]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[8]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[8]" :question="codeArr[8]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>10</label></td>
                            <td colspan="3">
                                <label class="form_question">Liquidación de Beneficios Sociales</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__10__yes" v-model="questionsArr[9]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__10__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__10__parcial" v-model="questionsArr[9]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__10__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__10__not" v-model="questionsArr[9]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__10__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__10__na" v-model="questionsArr[9]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__10__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[9]"/>   
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[9]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                <ButtonComment :comment="commentArr[9]" :question="codeArr[9]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>11</label></td>
                            <td colspan="3">
                                <label class="form_question">Examen Médico Ocupacional de Salida (EMOS)</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__11__yes" v-model="questionsArr[10]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__11__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__11__parcial" v-model="questionsArr[10]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__11__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__11__not" v-model="questionsArr[10]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__11__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__11__na" v-model="questionsArr[10]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__11__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[10]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[10]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[10]" :question="codeArr[10]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>12</label></td>
                            <td colspan="3">
                                <label class="form_question">Compensación de Tiempo de Servicios</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__12__yes" v-model="questionsArr[11]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__12__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__12__parcial" v-model="questionsArr[11]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__12__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__12__not" v-model="questionsArr[11]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__12__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__12__na" v-model="questionsArr[11]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__12__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[11]"/>  
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[11]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[11]" :question="codeArr[11]" @update-comment="updateComment($event)"/> 
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>13</label></td>
                            <td colspan="3">
                                <label class="form_question">Gratificaciones Ordinarias</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__13__yes" v-model="questionsArr[12]" class="custom-control-input" value="1" >
                                    <label class="custom-control-label" for="question__13__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__13__parcial" v-model="questionsArr[12]" class="custom-control-input" value="2" >
                                    <label class="custom-control-label" for="question__13__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__13__not" v-model="questionsArr[12]" class="custom-control-input" value="3" >
                                    <label class="custom-control-label" for="question__13__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__13__na" v-model="questionsArr[12]" class="custom-control-input" value="4" >
                                    <label class="custom-control-label" for="question__13__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[12]"/> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[12]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[12]" :question="codeArr[12]" @update-comment="updateComment($event)"/> 
                                </div>                        
                            </td>
                        </tr>
                        <tr>
                            <td><label>14</label></td>
                            <td colspan="3">
                                <label class="form_question">Participación en las Utilidades</label>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__14__yes" v-model="questionsArr[13]" class="custom-control-input" value="1" :checked="questionsArr[13]=='1' ? true : false">
                                    <label class="custom-control-label" for="question__14__yes">SÍ</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__14__parcial" v-model="questionsArr[13]" class="custom-control-input" value="2" :checked="questionsArr[13]=='2' ? true : false">
                                    <label class="custom-control-label" for="question__14__parcial">PARCIAL</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__14__not" v-model="questionsArr[13]" class="custom-control-input" value="3" :checked="questionsArr[13]=='3' ? true : false">
                                    <label class="custom-control-label" for="question__14__not">NO</label>
                                </div>
                            </td>
                            <td>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="question__14__na" v-model="questionsArr[13]" class="custom-control-input" value="4" :checked="questionsArr[13]=='4' ? true : false">
                                    <label class="custom-control-label" for="question__14__na">N.A.</label>
                                </div>
                            </td>
                            <td colspan="2">
                                <div>
                                    <ButtonUpload :code="codeArr[13]"/>   
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonReview  :dataReview="reviewArr[13]" @update-status="updateReview($event)" /> 
                                </div>                        
                            </td>
                            <td colspan="1">
                                <div>
                                    <ButtonComment :comment="commentArr[13]" :question="codeArr[13]" @update-comment="updateComment($event)"/>
                                </div>                        
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <!-- BUTTON SAVE -->
                <b-button 
                    class="btn-save btn"               
                    id="saveInfo"    
                    v-b-tooltip.hover.bottom.v-dark
                    title="Guardar Información"
                    variant="outline-primary"
                    @click="saveInformation" 
                >            
                    <feather-icon class="btn-icon"
                        icon="SaveIcon"                             
                    />
                </b-button>
                
                <!-- BUTTON SEND -->
                <b-button 
                    class="btn-send btn btn-lg"
                    @click="sendInformation"   
                >                      
                    Finalizar Revisión
                    <feather-icon size="1x"
                        icon="SendIcon"                             
                    />                          
                </b-button>    
                
                </b-form>    
            </b-col>            
        </b-row>
        
    </div>
    
</template>

<script>

    import ButtonUpload from '@core/components/button-upload/ButtonUpload.vue'
    import ButtonReview from '@core/components/button-review/ButtonReview.vue'
    import ButtonComment from '@core/components/button-comment/ButtonComment.vue'
    import ReviserHeader from '@core/components/reviser-header/ReviserHeader.vue'
    import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
    import JsonExcel from '../../../reporterias/download-excel';
    import { States } from '@core/utils/data'
    import { VBTooltip } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, positive, alphaNum, digits } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Ripple from 'vue-ripple-directive'
    import WorkersImportFile from './WorkersImportFile.vue'
    import { onUnmounted, ref } from '@vue/composition-api'
    import axios from '@axios'
    import router from '@/router'
    import moment from 'moment'
    import Swal from 'sweetalert2'
    import formStoreModule from '../formStoreModule'
    import store from '@/store'
    import vSelect from 'vue-select'

    export default {
        components: {
            ButtonUpload,
            ButtonReview,
            ButtonComment,
            ReviserHeader,
            StatisticCardHorizontal,
            WorkersImportFile,
            
            ToastificationContent,
            ValidationProvider, 
            ValidationObserver,
            JsonExcel,
            vSelect
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple
        },
        data(){
            
            return {
                States,
                required,
                email,
                alphaNum,
                positive,
                digits,

                isLoading: false,
                isSubmitDisabled: true,
                
                penalty_fixed_amount_status: '0',
                eliminate_ticket_loading_validation: '0',
                penalty_fixed_amount: null,
                amountPenalty: null,

                earrings: [],
                observations: [],
                previousFormId: null,
                previousFormDate: null,
                selectedObservations: [],

                infringementOptions: [
                    { value: '1', label: 'UNA' },
                    { value: '1.5', label: 'DE 2 HASTA 3' },
                    { value: '2', label: 'MÁS DE 3' }
                ],

                recidivismOptions: [
                    { value: '0', label: '1RA VEZ' },
                    { value: '1', label: '2DA VEZ' },
                    { value: '1.5', label: '3RA VEZ O MÁS' }
                ],

                impactOptions: [
                    { value: '0.25', label: 'LEVE' },
                    { value: '2', label: 'MODERADA' },
                    { value: '4', label: 'GRAVE' }
                ],

                companySizeOptions: [
                    { value: '1', label: 'HASTA 100' },
                    { value: '1.5', label: 'DE 101 HASTA 200' },
                    { value: '2', label: 'MÁS DE 201' }
                ],

                claimOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 3' },
                    { value: '3', label: '> 3' }
                ],

                paralyzeOptions: [
                    { value: '1', label: 'SOLO EL PROCESO' },
                    { value: '2', label: 'INDIRECTAMENTE' },
                    { value: '3', label: 'DIRECTAMENTE' }
                ],

                penaltyOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 3' },
                    { value: '3', label: '> 3' }
                ],

                laborDebtOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 500000' },
                    { value: '3', label: '> 500001' }
                ],

                laborOptions: [
                    { value: '3', label: '< 90%' },
                    { value: '2', label: '90% A 95%' },
                    { value: '1', label: '> 95%' }
                ],

                pensionDebtOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 200000' },
                    { value: '3', label: '> 200001' }
                ],

                procLabOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 3' },
                    { value: '3', label: '> 3' }
                ],

                monthOptions: [
                    { value: '1', label: '< 6' },
                    { value: '2', label: '6 A 12' },
                    { value: '3', label: '> 12' }
                ],

                questionsArr: [
                    null, null, null, null, null, null, null,  
                    null, null, null, null, null, null, null,    
                ],            
                codeArr: [
                    null, null, null, null, null, null, null,  
                    null, null, null, null, null, null, null, 
                ],
                reviewArr: [
                    null, null, null, null, null, null, null,  
                    null, null, null, null, null, null, null, 
                ],
                commentArr: [
                    null, null, null, null, null, null, null,  
                    null, null, null, null, null, null, null, 
                ],
                form_id:  router.currentRoute.params.id,      
                providerH: "",
                scoreH: 0,       
                dateH: "",
                dateFormCreated: "",

                penalty_amount_comment: null,
                infringement: null,
                recidivism: null,
                impact: null,
                company_size: null,
                monthly_valuation: null,
                doc_ref: null,
                clause: null,

                claims: null,
                paralyzes: null,
                penalties: null,
                laborDebt: null,
                labor: null,
                pensionDebt: null,
                procLab: null,
                months: null,

                comment_claims: null,
                comment_paralyzes: null,
                comment_penalties: null,
                comment_laborDebt: null,
                comment_labor: null,
                comment_pensionDebt: null,
                comment_procLab: null,
                comment_months: null,

                recommendations: null,

                contract_object: null,
                
                // Table
                totalRows: 1,
                currentPage: 1,
                stickyHeader: true,
                perPage: 10,
                pageOptions: [5, 10, 15, 20],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                workers: [],
                questionFiles: [],
                thClass: 'text-center',
                tdClass: 'text-center',
                fields: [],
                
                // Modal Worker
                okTitle: 'ACTUALIZAR',
                titleModal: 'ACTUALIZAR REGISTRO',
                actionModal: '',
                dataWorker: {
                    fullName: '',
                    dni: '',
                    role: '',
                    salary: '',
                    daily_wage: '',
                    phone: '',
                    email: '',
                    worker_state: {},
                    termination_reason: {}
                },
            
                state_id: null,
                formData: {},
                //business areas
                optionsPositons: [],
                positionsData: [],
                business_area_id: null,
                salaryTariff: null,
                tariff_id: null,
                
                RUBRO_MINA: 1,
                
                optionsCategory: ['EMPLEADO', 'OBRERO'],
                optionsWorkerStates: [],
                optionsTerminationReasons: []
            }
        },
        created(){
            this.getDataHeader()
        },
        computed: {
            sortOptions () {
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }))
            },
        },
        async beforeMount(){
            this.getAnswersInformation();
            await this.getFormInformation();
            await this.getPenaltyAmounts();
            await this.getJobCriticality();
            await this.getFileQuestion();
            await this.getpenaltyAmount();
        },
        watch: {
            penalty_fixed_amount_status (){
                this.penalty_fixed_amount = null
            }
        },
        methods: {
            customToast (success, message) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: message,
                        icon: success ? 'CheckIcon' : 'AlertTriangleIcon',
                        variant: success ? 'success' : 'danger'
                    }
                })
            },
            changePosition (position) {
                this.salaryTariff = ''
                if (position) {
                    const positionFound = this.positionsData.find( item => item.name === position) 
                    this.salaryTariff = positionFound ? positionFound.salary : ''
                }
            },
            async getpenaltyAmount (){
                let response = await axios.get(`/get-penalty-amount?id=${this.form_id}`)
                this.amountPenalty = response.data
            },
            async getPenaltyAmounts (){
                let response = await axios.get(`/view-form/${this.form_id}/data-penalty-amounts`)
                
                this.penalty_amount_comment = response.data.penalty_amount_comment

                if (response.data.infringement !== null){
                    this.infringement = this.infringementOptions.find(i => i.value == response.data.infringement)
                }

                if (response.data.recidivism !== null){
                    this.recidivism = this.recidivismOptions.find(i => i.value == response.data.recidivism)
                }

                if (response.data.impact !== null){
                    this.impact = this.impactOptions.find(i => i.value == response.data.impact)
                }

                if (response.data.company_size !== null){
                    this.company_size = this.companySizeOptions.find(i => i.value == response.data.company_size)
                }

                this.monthly_valuation = response.data.monthly_valuation
                this.doc_ref = response.data.doc_ref
                this.contract_object = response.data.contract_object
                this.clause = response.data.clause
            },
            async getJobCriticality (){
                let response = await axios.get(`/view-form/${this.form_id}/data-job-criticality`)

                if (response.data.claims !== null){
                    this.claims = this.claimOptions.find(i => i.value == response.data.claims)
                }

                if (response.data.paralyzes !== null){
                    this.paralyzes = this.paralyzeOptions.find(i => i.value == response.data.paralyzes)
                }

                if (response.data.penalties !== null){
                    this.penalties = this.penaltyOptions.find(i => i.value == response.data.penalties)
                }

                if (response.data.laborDebt !== null){
                    this.laborDebt = this.laborDebtOptions.find(i => i.value == response.data.laborDebt)
                }

                if (response.data.labor !== null){
                    this.labor = this.laborOptions.find(i => i.value == response.data.labor)
                }

                if (response.data.pensionDebt !== null){
                    this.pensionDebt = this.pensionDebtOptions.find(i => i.value == response.data.pensionDebt)
                }

                if (response.data.procLab !== null){
                    this.procLab = this.procLabOptions.find(i => i.value == response.data.procLab)
                }

                if (response.data.months !== null){
                    this.months = this.monthOptions.find(i => i.value == response.data.months)
                }

                this.comment_claims = response.data.comment_claims
                this.comment_paralyzes = response.data.comment_paralyzes
                this.comment_penalties = response.data.comment_penalties
                this.comment_laborDebt = response.data.comment_laborDebt
                this.comment_labor = response.data.comment_labor
                this.comment_pensionDebt = response.data.comment_pensionDebt
                this.comment_procLab = response.data.comment_procLab
                this.comment_months = response.data.comment_months

                this.recommendations = response.data.recommendations
            },
            async getQuestionFiles (){
                let response = await axios.get(`/provider/form/${this.form_id}/question/files`)
                this.questionFiles = response.data.questionFiles
            },
            async validateFiles (){
                let noFiles = []
                this.questionFiles.map((item, index) => {
                    // (this.questionsArr[index] == 1 || this.questionsArr[index] == 2) &&
                    if ((this.questionsArr[index] == 1 || this.questionsArr[index] == 2 || this.questionsArr[index] == 4) && !item.uploaded_files_count){
                        noFiles.push(item.id)
                    }
                })

                return noFiles
            },
            async sendInformation () {
                await this.getQuestionFiles()
                
                const dateFormCreated = moment(this.dateFormCreated)
                const dateWithNewFeature = moment('2023-07-01')

                if (this.tariff_id && dateFormCreated.isAfter(dateWithNewFeature)) {
                    
                    if (!this.workers.length) {
                        Swal.fire({
                            title: 'Planilla Vacía',
                            text: 'Debe tener, al menos, un empleado registrado en su planilla del mes',
                            icon: 'warning',
                            showConfirmButton: false,
                            timer: 5000,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false
                        })   
                        return
                    }
                                    
                    const incompleteWorkers = this.workers.some(worker => !worker.role || !worker.daily_wage)
                    if (incompleteWorkers) {
                        Swal.fire({
                            title: 'Planilla Incompleta',
                            text: 'Todos los trabajadores deben tener el cargo/puesto y el jornal diario. Completar los datos.',
                            icon: 'warning',
                            showConfirmButton: false,
                            timer: 5000,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false
                        })   
                        return
                    } 
                    
                    const workersFilesRequired = this.workers.filter( worker => worker.tariff_compliance != 1)
                    const filesUploaded = this.questionFiles[14].uploaded_files_count

                    if (workersFilesRequired.length > filesUploaded && this.eliminate_ticket_loading_validation == '0') {
                        Swal.fire({
                            title: 'Falta Boletas de Pago en Planilla',
                            html: `Debe cargar al menos ${workersFilesRequired.length} archivo(s) en la sección <strong>Cargar Boletas</strong>`,
                            icon: 'warning',
                            showConfirmButton: false,
                            timer: 5000,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false
                        })   
                        return
                    }  
                }
                
                let validateFilesRes = await this.validateFiles()
                if (validateFilesRes.length) {               

                    Swal.fire({
                        title: 'Error',
                        text: `Ups, La(s) pregunta(s) ${validateFilesRes.join(', ')} debe(n) tener al menos un archivo cargado.`,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 6500,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false
                    })   
                    return
                } 
                
                let dataForm = {
                    question__1: this.questionsArr[0],
                    question__2: this.questionsArr[1],
                    question__3: this.questionsArr[2],
                    question__4: this.questionsArr[3],
                    question__5: this.questionsArr[4],
                    question__6: this.questionsArr[5],
                    question__7: this.questionsArr[6],
                    question__8: this.questionsArr[7],
                    question__9: this.questionsArr[8],
                    question__10: this.questionsArr[9],
                    question__11: this.questionsArr[10],
                    question__12: this.questionsArr[11],
                    question__13: this.questionsArr[12],
                    question__14: this.questionsArr[13],
                    
                    question__1__reviewed: this.reviewArr[0].status,
                    question__2__reviewed: this.reviewArr[1].status,
                    question__3__reviewed: this.reviewArr[2].status,
                    question__4__reviewed: this.reviewArr[3].status,
                    question__5__reviewed: this.reviewArr[4].status,
                    question__6__reviewed: this.reviewArr[5].status,
                    question__7__reviewed: this.reviewArr[6].status,
                    question__8__reviewed: this.reviewArr[7].status,
                    question__9__reviewed: this.reviewArr[8].status,
                    question__10__reviewed: this.reviewArr[9].status,
                    question__11__reviewed: this.reviewArr[10].status,
                    question__12__reviewed: this.reviewArr[11].status,
                    question__13__reviewed: this.reviewArr[12].status,
                    question__14__reviewed: this.reviewArr[13].status,
                    
                    question__1__comments: this.commentArr[0],
                    question__2__comments: this.commentArr[1],
                    question__3__comments: this.commentArr[2],
                    question__4__comments: this.commentArr[3],
                    question__5__comments: this.commentArr[4],
                    question__6__comments: this.commentArr[5],
                    question__7__comments: this.commentArr[6],
                    question__8__comments: this.commentArr[7],
                    question__9__comments: this.commentArr[8],
                    question__10__comments: this.commentArr[9],
                    question__11__comments: this.commentArr[10],
                    question__12__comments: this.commentArr[11],
                    question__13__comments: this.commentArr[12],
                    question__14__comments: this.commentArr[13],

                    infringement: this.infringement ? this.infringement.value : null,
                    recidivism: this.recidivism ? this.recidivism.value : null,
                    impact: this.impact ? this.impact.value : null,
                    company_size: this.company_size ? this.company_size.value : null,
                    monthly_valuation: this.monthly_valuation,
                    doc_ref: this.doc_ref,
                    contract_object: this.contract_object,
                    clause: this.clause,

                    claims: this.claims ? this.claims.value : null,
                    paralyzes: this.paralyzes ? this.paralyzes.value : null,
                    penalties: this.penalties ? this.penalties.value : null,
                    laborDebt: this.laborDebt ? this.laborDebt.value : null,
                    labor: this.labor ? this.labor.value : null,
                    pensionDebt: this.pensionDebt ? this.pensionDebt.value : null,
                    procLab: this.procLab ? this.procLab.value : null,
                    months: this.months ? this.months.value : null,

                    comment_claims: this.comment_claims,
                    comment_paralyzes: this.comment_paralyzes,
                    comment_penalties: this.comment_penalties,
                    comment_laborDebt: this.comment_laborDebt,
                    comment_labor: this.comment_labor,
                    comment_pensionDebt: this.comment_pensionDebt,
                    comment_procLab: this.comment_procLab,
                    comment_months: this.comment_months,

                    recommendations: this.recommendations,

                    penalty_fixed_amount_status: this.penalty_fixed_amount_status,
                    penalty_amount_comment: this.penalty_amount_comment,
                    penalty_fixed_amount: this.penalty_fixed_amount
                }
              
                Swal.fire({
                    title: '¿Está seguro de enviar ésta información?',
                    text: "¡Asegúrese de haber guardado antes de enviar!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, enviar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {                    
                    axios.put(`/reviser/form/${this.form_id}/update`, dataForm)
                        .then( response => {        

                            Swal.fire({
                                title: response.data.message,
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 2700,
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                                buttonsStyling: false,
                            })                             
                            router.push({name: 'reviser-forms'})

                        })
                        .catch(err => {                
                            if(err) {

                                let num = err.response.data.score
                                this.scoreH = num.toFixed(2)

                                Swal.fire({
                                    title: 'Error',
                                    text: err.response.data.message,
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: err.response.data.timer,
                                    customClass: {
                                        confirmButton: 'btn btn-danger'
                                    },
                                    buttonsStyling: false
                                })  
                            }
                        })
                    }
                })
            },
            async saveInformation () {
                        
                let dataForm = {
                    question__1: this.questionsArr[0],
                    question__2: this.questionsArr[1],
                    question__3: this.questionsArr[2],
                    question__4: this.questionsArr[3],
                    question__5: this.questionsArr[4],
                    question__6: this.questionsArr[5],
                    question__7: this.questionsArr[6],
                    question__8: this.questionsArr[7],
                    question__9: this.questionsArr[8],
                    question__10: this.questionsArr[9],
                    question__11: this.questionsArr[10],
                    question__12: this.questionsArr[11],
                    question__13: this.questionsArr[12],
                    question__14: this.questionsArr[13],
                    
                    question__1__reviewed: this.reviewArr[0].status,
                    question__2__reviewed: this.reviewArr[1].status,
                    question__3__reviewed: this.reviewArr[2].status,
                    question__4__reviewed: this.reviewArr[3].status,
                    question__5__reviewed: this.reviewArr[4].status,
                    question__6__reviewed: this.reviewArr[5].status,
                    question__7__reviewed: this.reviewArr[6].status,
                    question__8__reviewed: this.reviewArr[7].status,
                    question__9__reviewed: this.reviewArr[8].status,
                    question__10__reviewed: this.reviewArr[9].status,
                    question__11__reviewed: this.reviewArr[10].status,
                    question__12__reviewed: this.reviewArr[11].status,
                    question__13__reviewed: this.reviewArr[12].status,
                    question__14__reviewed: this.reviewArr[13].status,
                    
                    question__1__comments: this.commentArr[0],
                    question__2__comments: this.commentArr[1],
                    question__3__comments: this.commentArr[2],
                    question__4__comments: this.commentArr[3],
                    question__5__comments: this.commentArr[4],
                    question__6__comments: this.commentArr[5],
                    question__7__comments: this.commentArr[6],
                    question__8__comments: this.commentArr[7],
                    question__9__comments: this.commentArr[8],
                    question__10__comments: this.commentArr[9],
                    question__11__comments: this.commentArr[10],
                    question__12__comments: this.commentArr[11],
                    question__13__comments: this.commentArr[12],
                    question__14__comments: this.commentArr[13],

                    penalty_amount_comment: this.penalty_amount_comment,
                    infringement: this.infringement ? this.infringement.value : null,
                    recidivism: this.recidivism ? this.recidivism.value : null,
                    impact: this.impact ? this.impact.value : null,
                    company_size: this.company_size ? this.company_size.value : null,
                    monthly_valuation: this.monthly_valuation,
                    doc_ref: this.doc_ref,
                    contract_object: this.contract_object,
                    clause: this.clause,

                    claims: this.claims ? this.claims.value : null,
                    paralyzes: this.paralyzes ? this.paralyzes.value : null,
                    penalties: this.penalties ? this.penalties.value : null,
                    laborDebt: this.laborDebt ? this.laborDebt.value : null,
                    labor: this.labor ? this.labor.value : null,
                    pensionDebt: this.pensionDebt ? this.pensionDebt.value : null,
                    procLab: this.procLab ? this.procLab.value : null,
                    months: this.months ? this.months.value : null,

                    comment_claims: this.comment_claims,
                    comment_paralyzes: this.comment_paralyzes,
                    comment_penalties: this.comment_penalties,
                    comment_laborDebt: this.comment_laborDebt,
                    comment_labor: this.comment_labor,
                    comment_pensionDebt: this.comment_pensionDebt,
                    comment_procLab: this.comment_procLab,
                    comment_months: this.comment_months,

                    recommendations: this.recommendations
                }
                
                await axios.put(`/reviser/form-a/${this.form_id}/update`, dataForm)
                .then( response => {
                                    
                    if(response.data.state) {                    
                        let num = response.data.score
                        this.scoreH = num.toFixed(2)
                        Swal.fire({                        
                            title: '¡Se ha guardado exitosamente!',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1200,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })    
                    } 
                })
                .catch( err => {
                    if(err){                          
                    Swal.fire({
                            title: 'Error',
                            text: 'Ups, hubo un error al guardar la información!',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })   
                    }              
                }) 
                
            },
            async getFileQuestion () {
                const response = await axios.get('/form/questions');
                this.codeArr = response.data;

                if (this.earrings.length)
                {
                    const questions = this.earrings.map(item => `- ${item.question}`);

                    this.codeArr[16].title = `LISTADO DE PENDIENTES DEL FORMULARIO (${this.previousFormDate})`;
                    this.codeArr[16].description = questions.join('<br/>');
                }			
            },
            getAnswersInformation () {
                axios.get(`/reviser/form-a/${router.currentRoute.params.id}`)
                .then(response => {
                    this.reviewArr = []
                    this.commentArr = []
                    if(response.data){
                        let cont1 = 0
                        let cont2 = 0         
                        let cont3 = 0         
                        for (const key in response.data) {                                         
                            if(key === 'question__'+(cont1+1)+'__comments'){                        
                                this.commentArr[cont1] = response.data[key]                                                
                                cont1++   
                            }
                            else if(key === 'question__'+(cont2+1)+'__reviewed'){                        
                                this.reviewArr[cont2] = {
                                status:  response.data[key] ? response.data[key] : false,
                                id: cont2+1,                                                
                                }
                                cont2++   
                            } else if(key === 'question__'+(cont3+1)) {
                                this.questionsArr[cont3] = response.data[key]   
                                cont3++
                            }
                        } 
                    } else {                    
                        for (let i = 0; i < this.codeArr.length; i++) {
                            this.questionsArr[i] = ""   
                            this.commentArr[i] = ""                            
                            this.reviewArr[i] = {
                                status: false,
                                id: i + 1,
                            }
                        }
                    }        
                })
                
            },
            updateComment (data) {
                this.commentArr[data.i-1] = data.comment    
            },
            updateReview (data) {     
                this.reviewArr[data.i-1].status = data.status           
            },
            getDataHeader () {
                axios.get(`/reviser-form/${router.currentRoute.params.id}`)
                .then(response => {
                    this.scoreH = response.data.score
                    this.dateH = moment(response.data.date_dispatch_review).format("D/M/YYYY")
                    this.providerH = response.data.provider.user.names
                    this.dateFormCreated = response.data.createdAt
                })
            },
            openSidebar () {
                
                Swal.fire({
                    title: 'Descargue la última plantilla con los nuevos cambios',
                    text: 'Omitir el mensaje si ya está en uso.',
                    icon: 'info',
                    showConfirmButton: true,
                    timer: 5000,
                    customClass: {
                        confirmButton: 'btn btn-info',
                    },
                    buttonsStyling: false
                }).then( _=> {
                    
                    this.$refs['refPlantillaModal'].hide()
                    this.isAddNewUserSidebarActive = true
                })   
            },   
            async getPositions () {
                this.optionsPositons = []
                this.positionsData = []
                if (this.tariff_id) {
                    const result = await axios.get(`/positions/select?tariff_id=${this.tariff_id}`)
                    if (result && result.data) {
                        this.positionsData = result.data
                        Array.from(new Set([...result.data.map( item => item.name)])).map( position => {
                            this.optionsPositons.push(position)
                        })   
                    }
                }
            },     
            async getFormInformation () {
                this.tariff_id = null;

                const { data } = await axios.get(`/provider/form/${this.form_id}/edit`);
                
                if (data) 
                {
                    const { earrings, previousFormId, previousFormDate, formData } = data;

                    this.formData = formData;
                    this.earrings = earrings;
                    this.previousFormId = previousFormId;
                    this.previousFormDate = previousFormDate;

                    this.business_area_id = formData.provider.business_area_id;
                    this.tariff_id = formData.tariff_id;
                    this.state_id = formData.state_id;
                    
                    await this.getWorkers();
                    await this.getWorkerStates();
                    await this.getTerminationReasons();
                }
            },    
            async getWorkers () {                
                await this.getPositions()
                const result = await axios.get(`/form/${this.form_id}/workers`)
                if (result && result.data) {                        
                    this.workers = result.data 
                    
                    if (this.tariff_id) {
                        this.workers = []
                        
                        result.data.map( item => {                                
                            let position = this.optionsPositons.find( position => position == item.role)
                            
                            this.workers.push({
                                id: item.id,
                                fullName: item.fullName,
                                dni: item.dni,
                                role: position || null,
                                category: item.category,
                                salary: item.salary ? Number(item.salary).toFixed(2) : null,
                                daily_wage: item.daily_wage ? Number(item.daily_wage).toFixed(2) : null,
                                tariff_compliance: item.tariff_compliance,
                                phone: item.phone,                            
                                email: item.email,
                                worker_state: item.worker_state,
                                termination_reason: item.termination_reason
                            })
                        })
                    }                        
                }
            },
            async getWorkerStates () {
                this.optionsWorkerStates = [];

                const { data } = await axios.get('/selects/worker-states');                 
                if (data.success) {
                    this.optionsWorkerStates = [ ...data.workerStates ];
                }
            },
            async getTerminationReasons() {
                this.optionsTerminationReasons = [];

                const { data } = await axios.get('/selects/termination-reasons');
                if (data.success) {
                    this.optionsTerminationReasons = [ ...data.terminationReasons ];
                }
            },
            viewWorkers () {                
                if (this.form_id) {                   
                    this.totalRows = this.workers.length
                    this.currentPage = 1
                    this.sortBy = ''
                    this.sortDesc = false
                    this.sortDirection = 'asc'
                    this.filter = null
                    
                    const styleColum = {
                        thClass: 'text-center',
                        tdClass: 'text-center'
                    }
                    this.fields = [{
                        key: 'id', 
                        label: '#',
                        sortable: true,
                        tdClass: styleColum.tdClass
                    }, {
                        key: 'fullName', 
                        label: 'Nombre completo',
                        sortable: true,                    
                        thClass: styleColum.thClass
                    }, {
                        key: 'dni', 
                        label: 'DNI',
                        sortable: true,
                        ...styleColum
                    }, {
                        key: 'role', 
                        label: 'Puesto/Cargo/Rol',
                        sortable: true,
                        ...styleColum
                    }, {
                        key: 'category', 
                        label: 'Categoría',
                        sortable: true,
                        ...styleColum
                    }, {
                        key: 'salary', 
                        label: 'Sueldo',
                        sortable: true,
                        thClass: styleColum.thClass,
                        tdClass: 'text-right'
                    }, {
                        key: 'daily_wage', 
                        label: 'Jornal Diario',
                        sortable: true,
                        thClass: styleColum.thClass,
                        tdClass: 'text-right'
                    }, {
                        key: 'phone', 
                        label: 'Celular',
                        sortable: true,
                        thClass: styleColum.thClass,
                    }, {
                        key: 'email', 
                        label: 'Correo electrónico',
                        sortable: true,
                        thClass: styleColum.thClass,
                    }, {
                        key: 'worker_state', 
                        label: 'Estado',
                        sortable: true,
                        ...styleColum
                    }]
                                        
                    if (this.tariff_id) {
                        const tariff_col = {
                            key: 'tariff_compliance', 
                            label: 'Análisis Tarifario',
                            sortable: false,
                            ...styleColum
                        }
                        this.fields.splice(this.fields.length - 1, 0, tariff_col);
                    }
                    
           /*          if (this.state_id === this.States.EN_REVISION){ */
                        this.fields.push({
                            key: 'actions', 
                            label: 'Opciones',
                            sortable: false,
                            ...styleColum
                        })
                    /* }                     */
                    this.$refs['refPlantillaModal'].show()
                }
            },
            removeCurrencyFormat (item, currency = 'PEN') {
                if (item) {
                    if (currency === 'PEN') item = item.replace('S/ ', '');

                    if (currency === 'USD') item = item.replace('$ ', '');

                    item = item.replaceAll(',', '');

                    return item
                }

                return '-'
            },
            downloadWorkers () {
                let array = [];

                for (let a = 0; a < this.workers.length; a++)
                {
                    let objBody = {
                        'ITEM': (a + 1),
                        'NOMBRE COMPLETO': this.workers[a]['fullName'],
                        'DNI': this.workers[a]['dni'],
                        'PUESTO/CARGO/ROL': this.workers[a]['role'],
                        'CATEGORÍA': this.workers[a]['category'],
                        'SUELDO': this.removeCurrencyFormat(this.workers[a]['salary']),
                        'JORNAL DIARIO': this.removeCurrencyFormat(this.workers[a]['daily_wage']),
                        'CELULAR': this.workers[a]['phone'],
                        'CORREO ELECTRÓNICO': this.workers[a]['email'],
                        'ESTADO': this.workers[a]['worker_state'] ? this.workers[a]['worker_state']['name'] : null,
                        'MOTIVO CESE': this.workers[a]['termination_reason'] ? this.workers[a]['termination_reason']['name'] : null
                    };

                    array.push(objBody);
                }

                return array;
            },
            onFiltered (filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            deleteWorker (id){
                Swal.fire({
                    title: '¿Estás seguro de eliminar al empleado(a) de la planilla?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then( async result => {
                    if (result.value) {
                        try {                            
                            const res = await axios (`/worker/${id}/delete`)
                            const result = this.workers.filter(worker => worker.id != id)
                            this.workers = result
                            this.customToast(true, res.data.message)
                        } catch (error) {
                            const message = 'Error al eliminar al empleado(a)'
                            this.customToast(false, message)
                        }
                    }
                })
            },
            validateRequiredFields () {
                if (this.dataWorker.fullName && 
                    this.dataWorker.dni && 
                    this.dataWorker.role && 
                    this.dataWorker.category && 
                    this.dataWorker.salary && 
                    this.dataWorker.daily_wage &&
                    this.dataWorker.worker_state &&
                    this.dataWorker.worker_state.id) {

                    this.isSubmitDisabled = false;
                } else {
                    this.isSubmitDisabled = true;                    
                }
            },
            validateFullname () {                
                const fullName = this.dataWorker.fullName.trim();
                const words = fullName.split(/\s+/);
                
                if (words.length < 2) {
                    const error = 'El Nombre Completo debe tener al menos un nombre y un apellido.';
                    this.customToast(false, error);
                    return false;
                }

                const regex = /^[\p{L}\s]+$/u;
                if (!regex.test(fullName)) {
                    const error = 'El Nombre Completo solo debe ser texto.';
                    this.customToast(false, error);
                    return false;
                }

                return true;
            },
            validateTerminationReason () {
                if (this.dataWorker.worker_state.name === 'CESADO' && !this.dataWorker.termination_reason) {
                    const error = 'Debe seleccionar el Motivo de Cese.'
                    this.customToast(false, error)
                    return false;
                }

                return true;
            },
            async addWorker (){
                this.salaryTariff = null
                this.actionModal = 'create'
                this.okTitle = 'AGREGAR'
                this.titleModal = 'AGREGAR REGISTRO'
                this.dataWorker = {
                    form_id: this.form_id,
                 /*    fullName: '',
                    role: '',
                    salary: '',
                    daily_wage: '',
                    phone: '',
                    email: '' */
                }

                this.isLoading = false;
                this.isSubmitDisabled = true;
                this.$refs['refWorkerModal'].show()
            },
            editWorker (data, index){
                const positionFound = this.positionsData.find( item => item.name === data.role) 
                this.salaryTariff = positionFound ? positionFound.salary : ''
                
                this.actionModal = 'update'
                this.okTitle = 'ACTUALIZAR'
                this.titleModal = 'ACTUALIZAR REGISTRO'
                this.dataWorker.index = index
                this.dataWorker.form_id = this.form_id
                this.dataWorker.id = data.id
                this.dataWorker.fullName = data.fullName
                this.dataWorker.dni = data.dni
                this.dataWorker.role = data.role
                this.dataWorker.category = data.category
                this.dataWorker.salary = data.salary
                this.dataWorker.daily_wage = data.daily_wage
                this.dataWorker.phone = data.phone
                this.dataWorker.email = data.email
                this.dataWorker.worker_state = data.worker_state
                this.dataWorker.termination_reason = data.termination_reason
                
                this.isLoading = false;
                this.isSubmitDisabled = true;
                this.$refs['refWorkerModal'].show()
            },
            resetModal() {
              /*   this.salaryTariff = null    */      
            },
            async viewObservations () {
                let { data } = await axios.get(`/form/${this.previousFormId}/observations`);
                this.observations = data;

                this.selectedObservations = [];

                this.observations.map(item => {
                    if (item.check) this.selectedObservations.push(item.id);
                });

                this.$refs['refObservationsModal'].show();
            },
            async onSubmit2 () {
                let data = this.selectedObservations.join(',');
                let response = await axios.put('/observations/update', { data, formId: this.previousFormId });

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                });

            },
            async refetchData () {
                await this.getWorkers()
            },
            async onSubmit() {
                this.$refs.workerForm.validate().then(async success => {
                    if (success) {                        
                        if (!this.validateFullname()) return;
                        if (!this.validateTerminationReason()) return;

                        let response = null
                        this.isLoading = true
                        if (this.actionModal == 'create') response = await axios.post('/worker/create', this.dataWorker)
                        if (this.actionModal == 'update') response = await axios.put(`/worker/${this.dataWorker.id}/update`, this.dataWorker)
                        
                        this.isLoading = false
                        if (response.data.res == 200){
                            await this.getWorkers()
                            this.$refs['workersTable'].refresh()
                            this.$refs['refWorkerModal'].hide()
                            this.customToast(true, response.data.message)
                            return
                        } 
                        this.customToast(false, response.data.message)
                    }
                })
            }
        },
        setup() {

            const isAddNewUserSidebarActive = ref(false)

            const USER_APP_STORE_MODULE_NAME = 'app-reviser-form'

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, formStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
            })

            return {
                isAddNewUserSidebarActive,    
            }
        }
    }

</script>

<style scoped>

    .content-table {
        border-collapse: collapse;
        margin: 25px 0;
        min-width: 400px;
        border-radius: 10px 10px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    .content-table thead tr th {
        font-weight: bold;
        font-size: 20px;
        padding: 45px 0px;
    }

    .content-table td {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .form_question{
        font-size: 1rem;
        margin-bottom: 0px;
    }
    
    .custom-control-label{
        font-size: .8rem;
    }
    
    .btn-send {
        outline: none;
        display: block;
        border: solid 1px #d8a400 !important;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        width: 100%;
        border-radius: 35px;
        color: #d8a400 !important;
        background: inherit !important;
        cursor: pointer;
        transition: all 0.3s linear;
        text-transform: uppercase;
        padding-top: 15px;
        padding-bottom: 15px;
        box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.15);
    }
    
    .btn-send:hover,
    .btn-send:focus {
        background: #d8a400 !important;
        color:  rgba(255, 255, 255, 0.842) !important;
    }
    
    .btn-send:active {
        color: #dfbb45;
    }

    .btn-save {        
        outline: none;
        position: fixed;
        width: 55px;
        height: 55px;
        line-height: 55px;
        bottom: 90px;
        right: 60px;
        color: rgba(255, 255, 255, 0.842);
        background: #d8a400;
        transition: all 0.3s linear;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
        cursor: pointer;
    }
    
    .btn-save:hover,
    .btn-save:focus {
        color:  rgba(255, 255, 255, 0.842);
    }
    
    .btn-save:active {
        background: #0020d8;
    }
    
    .btn-save .btn-icon{
        position: fixed;
        width: 30px;
        height: 40px;
        bottom: 95px;
        right: 72px;
    }
    
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
</style>